import {Field, Form, Formik} from 'formik'
import React, {ChangeEvent, useEffect, useState} from 'react'
import {createFormData, useCallApi} from '../../modules/utils'
import {useAuth} from '../../modules/auth'

import {Loading} from '../Loading'
import {toast} from 'react-toastify'
import { ModalAddProduct } from '../produits/ModalAddProduct'
import ComboBox from '../ComboBox'

type Props={
  add?:boolean
}
export const ModalAddInventaire:React.FC<Props> = ({add}) => {
  const callApi = useCallApi()
  const [loading, setLoading] = useState(false)
  const [chejed, setchejed] = useState(false)
  const [modalVisible, setModalVisible] = useState(true); // State to control modal visibility

  const initialValues = {
    name: '',
    order: '',
    magasin: '',
    color: '',
    status: true,
  }
  const [product, setProduct] = useState<any>()
  const [products, setproducts] = useState<any>([])
  const productsFromStorage = localStorage.getItem("products");


  const handelSumit = async (values: any) => {
     setLoading(true)
    const dataTosend = {
      product_id: product.id,
      comment: values.description,
      quantity: values.quantity
    }
    console.log(dataTosend);
    

    try {
      const dat = await callApi({
        route: `api/inventories`,
        method: 'POST',
        body: dataTosend,
      })
      setModalVisible(false);

      toast.success('ajoute avec succes')
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  }
  const getProducts = async () => {
    try {
   
  
      if (productsFromStorage) {
        const parsedProducts = JSON.parse(productsFromStorage);
        
        if (parsedProducts && parsedProducts.length > 0) {
          setLoading(false);
          
          setproducts(parsedProducts);
        }
      } else {
        setproducts([]); // Set products to an empty array or handle it as needed
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching product data:', error);
    }
  };
  
  useEffect(() => {
    getProducts();
  }, [productsFromStorage]); 
  return (
    <>
     <div
        className={`modal fade `}
        id='ModalAddInventory'
        role='dialog'
        tabIndex={-1}
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document' >
          <Formik
            initialValues={initialValues}
            onSubmit={(values: any, {resetForm}) => {
              handelSumit(values)
                resetForm()
              //   setFiles("")
            }}
          >
            {() => (
              <Form className='modal-content'>
               
                  <div className='modal-header ' >
                    <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                      Nouveau inventaire :
                    </h1>
                    <button
                      type='button'
                      className='btn-close text-white'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    ></button>
                  </div>
                  <div className='modal-body'>
                    <form>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Produit :
                            </label>
                            <ComboBox setProduct={setProduct}    />

                          </div>
                        </div>
                      
                      </div>
                      <div className='row mb-4'>
                        <div className='form-group'>
                          <label htmlFor='exampleInputEmail1' className='fw-bold'>
                            Description :
                          </label>
                          <Field
                            type='text'
                            name='description'
                            className='form-control'
                         
                          />
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <div className='form-group'>
                          <label htmlFor='exampleInputEmail1' className='fw-bold form-label'>
                            Quantité :
                          </label>
                          <Field
                            type='number'
                            min={0}
                            name='quantity'
                            className='form-control w-50'
                            id='exampleInputEmail1'
                            aria-describedby='text'
                          />
                        </div>
                      </div>

                   
                    </form>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                      Annuler
                    </button>
                    {loading ? (
                      <button type='button' className='btn btn-primary' >
                        <Loading />
                      </button>
                    ) : (
                      
                      <button type='submit' className='btn btn-primary' 
                      data-bs-dismiss="modal"
                      >
                        valider
                      </button>
                    )}
                  </div>
              
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
