/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../../../app/modules/utils'
import { useAuth } from '../../../../app/modules/auth'

type Props = {
  className: string
}

const ListsWidget3: React.FC<Props> = ({className}) => {
  const [sallesByCaissier, setSallesByCaissier] = useState<any>(null)
  const callApi = useCallApi()
  const {date,tab}=useAuth();

  const getSAlles = async () => {
    try {
      const {data} = await callApi({
        route: tab=="day" || tab=="dateP" || tab=="yesterday" ?'api/day-sales-by-caissier?date='+date:tab=="week"?'api/week-sales-by-caissier':tab=="month"?'api/month-sales-by-caissier':"api/year-sales-by-caissier",
        method: 'GET',
      })

      setSallesByCaissier(data.vente_by_caissier)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getSAlles()
  }, [tab,date])
  return (
    <div className={`card ${className}`}>
      {}
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Vente par Caissier</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {sallesByCaissier &&
          sallesByCaissier?.map((caissier: any, index: number) => {
            return (
              <div key={index}>
                <div className='d-flex align-items-center mb-8'>
                  {/* begin::Description */}
                  <div className='flex-grow-1'>
                    <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                      {caissier.first_name}
                    </a>
                  </div>
                  {/* end::Description */}
                  <span className='badge badge-light-success fs-8 fw-bold'>
                    {caissier.total} MAD
                  </span>
                </div>
                {/* end:Item */}
              </div>
            )
          })}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget3}
