import React, {useEffect, useRef, useState} from 'react'
import { useCallApi } from '../../modules/utils'
import { useAuth } from '../../modules/auth'
import { useThemeMode } from '../../../_metronic/partials'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { Loading } from '../Loading'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const ChartCategorie: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const [todaySales, setTodaySales] = useState<any>([])
  const callApi = useCallApi()
  const {date, tab} = useAuth()

  const getSales = async () => {
    try {
     const {data} = await callApi({
        route: tab=="day" || tab=="dateP" || tab=="yesterday" ?'api/day-sales-by-categorie?date='+date:tab=="week"?'api/week-sales-by-categorie':tab=="month"?'api/month-sales-by-categorie':"api/year-sales-by-categorie",
        method: 'GET',
      })
      setTodaySales(data.vente_by_category)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getSales()
  }, [tab, date])

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = (data: any) => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, data))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart(todaySales)

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, todaySales])

  return (
    <div className={`card ${className}`}>
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bold text-gray-800 d-block fs-3'>Les 10 Top Catégories des produits vendus:</span>
          </div>
        </div>
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, salesData: any): ApexOptions => {
  if (!salesData || salesData.length === 0) {
    return {
      series: [],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: chartHeight,
        toolbar: {
          show: false,
        },
      },
      // ... other default chart options
    }
  }

  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-' + chartColor)

  const productNames = salesData.map((item: any,index:number) => item.name).slice(0,10)
  const quantities = salesData.map((item: any,index:number) => item.total).slice(0,10)

  return {
    series: [
      {
        name: 'Total vendu',
        data: quantities,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: false // Disable the numbers inside the bars
    },
    xaxis: {
      categories: productNames,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
    
      labels: {
        formatter: function (value: any) {
          return value + ' MAD' // Ensure the y-axis labels are integers
        },
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value: any) {
          return value + ' MAD' 
        },
      },
    },
    colors: [baseColor],
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}



export {ChartCategorie}
