import React, { useState } from 'react';
import Select from 'react-select';
import { useField, useFormikContext } from 'formik';
import axios from 'axios';

type Option = {
    value: string;
    label: string;
  };
  
type Props = {
name: string;
entity:string;
loadOptions: (inputValue: string,entity:string) => Promise<Option[]>;
};
const AsyncSelectField: React.FC<Props>  = ({ name, loadOptions,entity }) => {
  const [field, , helpers] = useField(name);
  const { setFieldValue } = useFormikContext();
  const [options, setOptions] = useState<any>([]);

  const handleInputChange = (inputValue:string) => {
    loadOptions(inputValue,entity).then((newOptions:any[]) => {

      setOptions(newOptions);
    });
  };

  const handleChange = (selectedOption:any) => {

    setFieldValue(name, selectedOption ? selectedOption?.value : '');
  };
console.log(name);

  
  return (
    <Select
      options={options}
      onInputChange={handleInputChange}
      onChange={handleChange}
      value={options.find((option:Option) => option.value == field.value)}
      isClearable
    />
  );
};

export default AsyncSelectField;
