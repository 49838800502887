import * as React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { API_ROUTE } from '../modules/utils';

type Props = {
  idProduct?: number;
  index?: number;
  setProduct: (id: any) => void;
  updatePurchaseItem?: CallableFunction;
};

const ComboBox: React.FC<Props> = ({ setProduct, idProduct, updatePurchaseItem, index }) => {
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [inputValue, setInputValue] = useState<string>(''); // Track the input value
  const [options, setOptions] = useState<any[]>([]); // State to hold the fetched products

  useEffect(() => {
    setSelectedProduct(idProduct);
  }, [idProduct]);

  // Function to fetch products from API based on the search input
  const fetchProducts = async (query: string) => {
    try {
      const {data} = await axios.get(API_ROUTE+'/api/products?filter[search]='+query);
      setOptions(data.data);
      console.log(data.data);
      
    } catch (error) {
      console.error('Failed to fetch products', error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue.trim()) {
      fetchProducts(newInputValue.trim());
    } else {
      setOptions([]);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Tab") {
      const input = inputValue.trim().toLowerCase();
      if (input) {
        const filteredOptions = options.filter(
          (option: any) =>
            option?.attributes?.code.toLowerCase().includes(input) ||
            option?.attributes?.name.toLowerCase().includes(input)
        );

        if (filteredOptions.length === 1) {
          setSelectedProduct(filteredOptions[0]);
          setProduct(filteredOptions[0]);
          updatePurchaseItem && updatePurchaseItem(index, "product_id", filteredOptions[0].id);
        }
      }
    }
  };

  return (
    <Autocomplete
      className='text-dark fw-bold d-block fs-6'
      disablePortal
      id="combo-box-demo"
      options={options}
      value={selectedProduct}
      getOptionLabel={(option) => option?.attributes?.code + " - " + option?.attributes?.name}
      inputValue={inputValue} // Set the inputValue for Autocomplete
      onInputChange={handleInputChange} // Update the inputValue
      onChange={(event, newValue) => {
        updatePurchaseItem && updatePurchaseItem(index, "quantity", 0);
        updatePurchaseItem && updatePurchaseItem(index, "sub_total", 0);
        setSelectedProduct(newValue);
        setProduct(newValue);
      }}
      // filterOptions={(options, { inputValue }) => {
      //   console.log(options);
        
      //   const input = inputValue?.trim().toLowerCase();
      //   // return options.filter(
      //   //   (option) =>
      //   //     option?.attributes?.code.toLowerCase().includes(input) ||
      //   //     option?.attributes?.name.toLowerCase().includes(input)
      //   // );
      // }}    
        sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} onKeyDown={handleKeyDown} />}
    />
  );
};

export default ComboBox;
