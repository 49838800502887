import React, {useEffect, useState} from 'react'
import {ItemProductPurchase} from './ItemProductPurchase'
import '../../css/style.css'
import {KTSVG} from '../../../_metronic/helpers'
import { ToastContainer } from 'react-bootstrap'
import { Loading } from '../Loading'
  type Props = {
  total?: number
  setDataUpdate: any
  saveData: () => void
  update:boolean
}
export const ListProductPurhase: React.FC<Props> = ({update,total, setDataUpdate, saveData}) => {

  
  // const [listProducts, setListProducts] = useState<any>(listProduct);
  const addRow = () => {
    const newData = {
      discount_amount: 0,
      discount_type: '1',
      discount_value: 0,

      net_unit_cost: 0,


      tax_amount: 0,
      tax_type: '1',
      tax_value: 0,

      id: null,
      product_id: null,
      product_cost: 0,

      quantity: 0,
      purchase_price: 0,
      product_price: 0,
    }
    setDataUpdate[1]({
      ...setDataUpdate[0],
      purchase_items: [...setDataUpdate[0].purchase_items, newData],
    })
    // setListProducts([...listProducts, newData]);
  }
  const deleteRow = (index: number) => {
    const updatedList = [...setDataUpdate[0]?.purchase_items]
    console.log(setDataUpdate[0]?.purchase_items);
    
    updatedList.splice(index, 1)
    console.log(updatedList);

    setDataUpdate[1]({
      ...setDataUpdate[0],
      purchase_items: updatedList,
    })

    
  }

  useEffect(() => {
    calculateGrandTotal()
    
  }, [setDataUpdate[0].purchase_items])

  const calculateGrandTotal = () => {
    
    const subtotalArray = setDataUpdate[0] && setDataUpdate[0]?.purchase_items?.map((item: any) =>
       parseFloat(item.sub_total?item.sub_total : item?.data?.attributes?.sub_total)
    
     

    )

    const grandTotal = subtotalArray && subtotalArray?.reduce((acc: any, curr: any) => acc + curr, 0)
    setDataUpdate[1]((prevData: any) => ({
      ...prevData,
      grand_total: grandTotal,
    }))

  }

  


  
  return (
    <>
    {/* <ToastContainer/> */}
     <div className='row  '>
      <div>
        <div className='card'>
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive' style={{height: "30em"}}>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
  {/* begin::Table head */}
  <thead>
    <tr className='fw-bold text-muted'>
      <th className='min-w-50px'>Nom Produit</th>
      <th className='min-w-50px'>Stock N.C</th>
      <th className='min-w-50px'>Stock C</th>

      <th className='min-w-50px'>Quantité</th>
      <th className='min-w-50px'>Prix d'achat</th>
      <th className='min-w-50px'>Prix de vente</th>
      <th className='min-w-50px'>Montant</th>
      <th className='min-w-10px text-end'>Actions</th>
    </tr>
  </thead>
  {/* end::Table head */}
  {/* begin::Table body */}
  <tbody>
    {
       setDataUpdate[0].purchase_items?.map((listP: any, index: number) => {
        return (
          <ItemProductPurchase
            calculateGrandTotal={calculateGrandTotal}
            index={index}
            data={listP}
            update={update}
            deleteRow={() => deleteRow(index)}
            setDataUpdate={setDataUpdate}
          />
        )
      })
    }
  </tbody>
  {/* end::Table body */}
  <tfoot>
    <tr>
      <th colSpan={5}></th>
      <th colSpan={2} className='d-inline-block col-12'>
        <span className='text-gray-700 fw-bold'>Total:</span>{' '}
        <span id='app-order-total' className='text-gray-700 fw-bold'>
          {total ? total.toFixed(2) : 0} DH
        </span>
      </th>
    </tr>
  </tfoot>
</table>

              {/* end::Table */}
            </div>
            <div className='d-flex  justify-content-between'>
              <button type='button' onClick={addRow} className='btn btn-sm btn-primary '>
                <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
                Ajouter Article
              </button>
              {
                 setDataUpdate[2] ||  setDataUpdate[3]
                  ?
                 <button type='button' className='btn btn-sm btn-primary '>
                 <Loading/>
               </button>:
                <button type='button' onClick={saveData} className='btn btn-sm btn-primary '>
                <KTSVG path='/media/icons/duotune/arrows/arr016.svg' className='svg-icon-2' />
                Valider
              </button>
              }
             
            </div>

            {/* end::Table container */}
          </div>
        </div>
      </div>
    </div></>
   
  )
}
