/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useCallApi} from '../../../../app/modules/utils'

type Props = {
  className: string
}

const ListsWidget1: React.FC<Props> = ({className}) => {
  const [sallesCaisse, setSallesCaisse] = useState<any>(null)
  const callApi = useCallApi()

  const getSallesCaisse = async () => {
    try {
      const {data} = await callApi({
        route: 'api/best-customers-report',
        method: 'GET',
      })

      setSallesCaisse(data.top_customers)

      // console.log(data.data.today_sales_total_amount)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getSallesCaisse()
  }, [])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Vente par top client</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5'>
        {sallesCaisse &&
          sallesCaisse.map((caisse: any, index: number) => {
            return (
              <div
                className='d-flex align-items-center justify-content-between mb-7 border-top pt-5'
                key={index}
              >
                {/* begin::Symbol */}
                <div className='symbol symbol-50px me-5'>{caisse.name}</div>

                <div className='d-flex flex-column'>
                  <span className='badge badge-light-success'>{caisse.grand_total} MAD</span>
                </div>
                {/* end::Text */}
              </div>
            )
          })}
        {/* begin::Item */}

        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget1}
