import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../modules/utils'

import SearchComponent from '../SearchComponent'

import { ItemProductTurnover } from './ItemProductTurnover'

// type Props = {
//   states: any
//   // deleteVille:(id:number)=>void
// }
export const ProductTurnover = ({}) => {
  const [products, setProducts] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filterProducts, setFilterProducts] = useState<any>([])

  const callApi = useCallApi()


 
 

 

  // useEffect(() => {
  //   getProducts()
  //   setFilterProducts(products)
  // }, [states.currentPage, states.totalItems])
  const getProductByName = async () => {
    try {
      setLoading(true)
      const {data} = await callApi({
        route: `api/total-stock-amount-purchase`,
        method: 'GET',
      })

      
   

      setProducts(data.data.turnoverPerProduct)
     
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }


  useEffect(() => {
    setFilterProducts(products)
  }, [products])

  const searchProduct = () => {
    // Replace 'yourSearchTerm' with the actual search term

    const filteredProducts = products.filter(product =>
      product.name_product.toLowerCase().includes(searchTerm.toLowerCase()) || product.code == searchTerm
  );
    

    setFilterProducts(filteredProducts);
  };
  useEffect(() => {
    getProductByName()
  }, [])
  useEffect(() => {
    searchProduct()
  }, [searchTerm])

  return (
    <>
      <div className={`card }`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Produits
              <SearchComponent
                placeholder={'Recherche produit'}
                searchQuery={[searchTerm, setSearchTerm]}
              />
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
           
          >
           
            {/* {itemsSelected != 0 ? (
              <div className='d-flex justify-content-end align-items-center'>
                <div className='fw-bolder me-5'>
                  <span className='me-2'>{itemsSelected}</span> Selectionnées
                </div>
                <button
                  type='button'
                  className='btn  btn-sm btn-danger'
                  onClick={() => {
                    deleteInventory(checkInventoryId)
                  }}
                >
                  Supprimer les sélectionnés
                </button>
              </div>
            ) : (
              <>
                {' '}
                <button
                  type='button'
                  className='btn btn-sm btn-primary me-3'
                  title='actualiser'
                  onClick={()=> getProductByName()}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr029.svg' className='svg-icon-2' />
                 
                </button>
                <button
                  type='button'
                  className='btn btn-sm btn-primary '
                  data-bs-toggle='modal'
                  data-bs-target='#ModalAddInventory'
                >
                  <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
                  Ajouter un inventaire 
                </button>
                <ModalAddInventaire />
              </>
            )} */}
          </div>
        </div>
        {loading ? (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                      {/* <th>
                    <div className='form-check form-check-custom form-check-solid mx-5'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={filterProducts.every((product: any) => product?.checked)}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </div> 
                    </th>*/}
                    <th className='min-w-150px '>Produit</th>
                    {/* <th className="min-w-150px">Pays</th> */}
                    <th className='min-w-150px text-center'>Chiffre d'affaires</th>

                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {filterProducts.map((categoreie: any, index: any) => {
                    return (
                      <ItemProductTurnover
                        // deleteInventory={deleteInventory}
                        key={index}
                        data={categoreie}
                      />
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  )
}
