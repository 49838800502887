import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers';
import axios from 'axios';
import { callApi, useCallApi } from '../../modules/utils';
import { ItemsProducts } from '../../components/produits/ItemsProducts';
import { TablesWidget10 } from '../../../_metronic/partials/widgets';
import { PaginationCustom } from '../../components/produits/PaginationCustom';
import { UsersListPagination } from '../../modules/apps/user-management/users-list/components/pagination/UsersListPagination';
import { Pagination } from '@mui/material';
import { ListProducts } from '../../components/produits/ListProducts';
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

type Props= {
  byDashboard: boolean;
}

export  const Products:React.FC<Props> = ({byDashboard}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  function handlePageChange(event:any, value:any) {
    setCurrentPage(value);

  }
  return (
    <>

<ToastContainer/>

    <ListProducts states={{currentPage,setCurrentPage,totalItems,setTotalItems,setTotalPages,byDashboard}}/>
    <Pagination count={totalPages} page={currentPage} color="primary"  onChange={handlePageChange}  className="d-flex justify-content-center"/>

    
    </>

  )
}
