import React, { useEffect, useState } from 'react'


type Props = {
  data: any
}
export const ItemProductTurnover: React.FC<Props> = ({
  data,
}) => {
   
  return (
    <>
      {' '}
      <tr>
        
        <td>
          <div className='d-flex align-items-center'>
            {/* / */}

            <div className='d-flex justify-content-start flex-column'>
            { data?.name_product }<br/>


           </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            <span className='text-dark fw-bold text-center d-block fs-6'>
              <div className='card-toolbar ml-3'>
              { data?.turnover }
              </div>
            </span>
          </div>
        </td>
      
      </tr>
    </>
  )
}
