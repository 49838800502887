/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../_metronic/partials'
import {useCallApi} from '../../modules/utils'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import { useAuth } from '../../modules/auth'
import { Loading } from '../Loading'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  date:any
}

const ChartByWeek: React.FC<Props> = ({className, chartColor, chartHeight,date}) => {
  const [weekSalles, setWeekSalles] = useState<any>(null)
  const [totalSalles, setTotalSalles] = useState<any>(0)
  const callApi = useCallApi()
  const {tab}=useAuth()

  const [loading, setLoading] = useState(true)

  const getSalles = async () => {
    try {
      setLoading(true)
      const {data} = await callApi({
        route: 'api/today-sales-overall-report?date='+date,
        method: 'GET',
      })
      setWeekSalles(data.data.total_sales_by_week)
      setTotalSalles(data.data.total_week_amount)
      setLoading(false)

    } catch (error) {
      setLoading(false)
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getSalles()
  }, [tab])

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = (data: any) => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, data))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart(weekSalles)

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, weekSalles])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bold text-gray-800 d-block fs-3'>Vente</span>
            {/* <span className='text-gray-400 fw-semibold'>Oct 8 - Oct 26 2021</span> */}
          </div>
          <div className={`fw-bold fs-3 text-${chartColor}`}>Ventes du semaine: {loading?<Loading/>:totalSalles} MAD</div>
        </div>
        {/* end::Hidden */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, weekSalles: any): ApexOptions => {
  if (!weekSalles) {
    return {
      series: [],
      chart: {
        fontFamily: 'inherit',
        type: 'line',
        height: chartHeight,
        toolbar: {
          show: false,
        },
      },
      // ... other default chart options
    }
  }

  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')
  const baseColor = getCSSVariableValue('--kt-' + chartColor)

  return {
    series: [
      {
        name: 'Total ventes',
        data: [
          weekSalles['1'],
          weekSalles['2'],
          weekSalles['3'],
          weekSalles['4'],
          weekSalles['5'],
          weekSalles['6'],

        ],
      },
    ],

    chart: {
      fontFamily: 'inherit',
      type: 'line',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    xaxis: {
      categories: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',

      ],
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },

    yaxis: {
      labels: {
        formatter: function (value: any) {
          return value + '  MAD'
        },
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value: any) {
          return value + '  MAD'
        },
      },
    },
    colors: [baseColor],
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export {ChartByWeek}
