import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { ListeInventaire } from '../../components/inventaire/ListeInventaire'
import { Pagination } from '@mui/material'
import { ProductTurnover } from '../../components/ListProdcutTurnover/ProductTurnover'

const ListProductTurnover = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    function handlePageChange(event: any, value: any) {
      setCurrentPage(value)
    }
  return (
    <div>  
        
    <ProductTurnover/>
    {/* <Pagination
      count={totalPages}
      page={currentPage}
      color='primary'
      onChange={handlePageChange}
      className='d-flex justify-content-center'
    />
     */}</div>
  )
}

export default ListProductTurnover