/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG } from '../../../_metronic/helpers'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: number
  descriptionColor?: string
  borderleftColor?:string
}

const CardDetails: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  borderleftColor,
}) => {
  return (
    <div className="card border-left-primary shadow h-100 py-2 animate-up" style={{borderLeft: "0.25rem solid "+{borderleftColor} +" !important"}}>
    <div className="card-body">
        <div className="row no-gutters align-items-center">
            <div className="col mr-2">
                <div className={`text-xs font-weight-bold  fw-bold text-${titleColor} text-uppercase mb-1`}>{title}</div>
                <div className="h5 mb-0 fw-bold text-gray-800">{description}</div>
            </div>
            <div className="col-auto">
                <i className={`fas ${svgIcon}  fa-2x text-300 text-${iconColor}`}></i>
            </div>
        </div>
    </div>
</div>
  )
}

export {CardDetails}
