import {Field, Form, Formik} from 'formik'
import React, {ChangeEvent, useEffect, useState} from 'react'
import {createFormData, useCallApi} from '../../modules/utils'
import {useAuth} from '../../modules/auth'

import {Loading} from '../Loading'
import {toast} from 'react-toastify'
import { ModalAddProduct } from '../produits/ModalAddProduct'
import ComboBox from '../ComboBox'

type Props={
  add?:boolean
}
export const ModalAddBrand:React.FC<Props> = ({add}) => {
  const callApi = useCallApi()
  const [loading, setLoading] = useState(false)
  const [chejed, setchejed] = useState(false)
  const [modalVisible, setModalVisible] = useState(true); // State to control modal visibility

  const initialValues = {
    name: '',
    order: '',
    magasin: '',
    color: '',
    status: true,
  }
 
  const [image, setImage] = useState<File | any>([])

  const [filess, setFiles] = useState<string>()


  const handelSumit = async (values: any) => {
     setLoading(true)
    const dataTosend = {
      description: values.description,
      name: values.name,
      image:image[0]
    }

    try {
      const dat = await callApi({
        route: `api/brands`,
        method: 'POST',
        body: dataTosend,
      })
      setModalVisible(false);

      toast.success('ajoute avec succes')
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  }

  

  return (
    <>
     <div
        className={`modal fade `}
        id='ModalAddBrand'
        role='dialog'
        tabIndex={-1}
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document' >
          <Formik
            initialValues={initialValues}
            onSubmit={(values: any, {resetForm}) => {
              handelSumit(values)
                resetForm()
              //   setFiles("")
            }}
          >
            {() => (
              <Form className='modal-content'>
               
                  <div className='modal-header ' >
                    <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                      Nouveau marque :
                    </h1>
                    <button
                      type='button'
                      className='btn-close text-white'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    ></button>
                  </div>
                  <div className='modal-body'>
                    <form>
                      <div className='row mb-4'>
                        <div className='col'>
                        <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        Photo :
                      </label>
                      <div className='form-group mb-5  '>
                        <div
                          className='image-input image-input-outline'
                          data-kt-image-input='true'
                          style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}
                        >
                          <div
                            id='image'
                            className='image-input-wrapper w-125px h-125px'
                            style={{
                              backgroundImage: filess == '' ? "url('')" : 'url(' + filess + ')',
                            }}
                          ></div>
                          <label
                            className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                            data-kt-image-input-action='change'
                            data-bs-toggle='tooltip'
                            data-bs-dismiss='click'
                            title='Change avatar'
                          >
                            <i className='bi bi-pencil-fill fs-7'></i>

                            <Field
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                // const reader = new FileReader();
                                // reader.onload=function(e){
                                //   setUrl(e.target?.result)
                                // }
                                // reader.readAsDataURL(e.target.files[0])
                                if (e.target.files && e.target.files[0]) {
                                  setFiles(URL.createObjectURL(e.target.files[0]))
                                  setImage(Array.from(e.target.files))
                                }
                              }}
                              type='file'
                              name='image'
                              accept='.png, .jpg, .jpeg'
                            />
                            <Field type='hidden' name='avatar_remove' />
                          </label>

                          <span
                            className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                            data-kt-image-input-action='cancel'
                            data-bs-toggle='tooltip'
                            data-bs-dismiss='click'
                            title='Cancel avatar'
                          >
                            <i className='bi bi-x fs-2'></i>
                          </span>
                        </div>
                      </div>
                        </div>
                      
                      </div>
                      <div className='row mb-4'>
                      <div className='form-group'>
                          <label htmlFor='exampleInputEmail1' className='fw-bold'>
                            Nom :
                          </label>
                          <Field
                            type='text'
                            name='name'
                            className='form-control'   
                          />
                        </div>
                      </div>

                      <div className='row mb-4'>
                      <div className='form-group'>
                          <label htmlFor='exampleInputEmail1' className='fw-bold'>
                            Description :
                          </label>
                          <Field
                            type='text'
                            name='description'
                            className='form-control'
                         
                          />
                        </div>
                      </div>

                   
                    </form>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                      Annuler
                    </button>
                    {loading ? (
                      <button type='button' className='btn btn-primary' >
                        <Loading />
                      </button>
                    ) : (
                      
                      <button type='submit' className='btn btn-primary' 
                      data-bs-dismiss="modal"
                      >
                        valider
                      </button>
                    )}
                  </div>
              
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
