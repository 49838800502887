import React, { ChangeEvent, useEffect, useState } from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {ProductDetails} from '../../pages/products/ProductDetails'

import { createFormData, useCallApi } from '../../modules/utils'
import { toast } from 'react-toastify'
import { Loading } from '../Loading'

type Props = {
  data: any
  handleProductCheckboxChange: any
}
export const ItemBrand: React.FC<Props> = ({
  data,
  handleProductCheckboxChange,
}) => {
  
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState<File | any>([]);

    const [filess, setFiles] = useState<string>()
    const [dataIn, setDataIn] = useState<any>({
      description:data?.attributes?.description,
        name:data?.attributes?.name
    })
    console.log(dataIn);
    
    
    const callApi = useCallApi()

   
    const updateInv= async(id:number)=>{
      setLoading(true)
      var dataTosend = null;

      if (image.length > 0) {
        dataTosend = {
          description: dataIn.description,
          name: dataIn.name,
          image: image[0],
        };
      } else {
        dataTosend = {
          description: dataIn.description,
          name: dataIn.name,
        };
      }
      
     
          console.log(dataTosend);
          
          const formData = createFormData(dataTosend) 
          try {
            const dat = await callApi({
              route: `api/brands/${id}`,
              method: 'POST',
              body: formData,
            })
          
            toast.success('modifié avec succes')
            setLoading(false)
          } catch (error: any) {
            toast.error(error.response.data.message)
            setLoading(false)
          }
    }
  
  return (
    <>
      {' '}
      <tr>
        <td>
       
        <div className='form-check form-check-custom form-check-solid mx-5'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={data?.checked}
              onChange={handleProductCheckboxChange(data?.id)}
            />
          </div>
        </td>
  <td>
    <div className="row">
      <div
        className="col-2 image-input image-input-outline"
        data-kt-image-input="true"
        style={{
          backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)',
        }}
      >
        <div className="d-flex">
          {image.length === 0 ? (
            <div className="symbol symbol-45px me-5">
              <img
                src={
                  data.attributes.image!=""
                    ? data.attributes.image
                    : "/media/products/productdefault.png"
                }
                alt=""
              />
            </div>
          ) : (
            image.map((file: any, index: number) => (
              <div className="symbol symbol-45px me-3" key={index}>
                <img src={URL.createObjectURL(file)} alt="" />
              </div>
            ))
          )}
        </div>

        <label
          className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow ml-5"
          data-kt-image-input-action="change"
          data-bs-toggle="tooltip"
          data-bs-dismiss="click"
          title="Change avatar"
        >
          <i className="bi bi-pencil-fill fs-7"></i>

          <input
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.files && e.target.files[0]) {
                setFiles(URL.createObjectURL(e.target.files[0]));
                setImage(Array.from(e.target.files));
              }
            }}
            type="file"
            name="avatar"
            accept=".png, .jpg, .jpeg"
            multiple
          />

          <input type="hidden" name="avatar_remove" />
        </label>

        <span
          className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
          data-kt-image-input-action="cancel"
          data-bs-toggle="tooltip"
          data-bs-dismiss="click"
          title="Cancel avatar"
        >
          <i className="bi bi-x fs-2"></i>
        </span>
      </div>

      <div className="col-9 justify-content-start">
        <input
          className="d-none"
          type="text"
          value={data.id}
        />
        <input
          type="text"
          onChange={(e) => setDataIn({ ...dataIn, name: e.target.value })}
          value={dataIn.name}
          className="form-control text-dark fw-bold w-100 d-block fs-6"
        />
      </div>
    </div>
  </td>

  <td>
    <div className="d-flex justify-content-center text-center flex-column">
      <textarea
        rows={2}
        onChange={(e) => setDataIn({ ...dataIn, description: e.target.value })}
        className="form-control text-dark fw-bold d-block fs-6"
      >{dataIn.description}</textarea>
    </div>
  </td>

  <td>
    <div className="d-flex justify-content-end flex-shrink-0">
      <a
        href="#"
        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
        onClick={() => updateInv(data.id)}
      >
        {
          loading?<Loading/>:        <KTSVG path="/media/icons/duotune/general/gen037.svg" className="svg-icon-3" />

        }
      </a>
    </div>
  </td>
</tr>

    </>
  )
}
